import { Show, children, createSignal, splitProps } from "solid-js";
import type { JSX } from "solid-js/jsx-runtime";
import ArrowIcon from "~/components/shared/Icons/Arrow";

interface CardLayoutProps {
  text?: string;
  title?: string;
  subtitle?: string;
  titleFont?: string;
  titleSize?:
    | "xs"
    | "sm"
    | "base"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "8xl"
    | "9xl";
  textFont?: string;
  classList?: string[];
  isPath?: boolean;
  pageTitle?: boolean;
  as?: "card" | "content";
  children?: JSX.Element;
  loading?: boolean;
}

export const CardLayout = (props: CardLayoutProps) => {
  // eslint-disable-next-line solid/reactivity
  const as = props.as || "card"; // Default to 'card' if no 'as' prop is provided
  const c = children(() => props.children);
  const [local] = splitProps(props, [
    "isPath",
    "as",
    "loading",
    "classList",
    "subtitle",
    "title",
    "titleFont",
    "titleSize",
    "children",
  ]);
  const [show, setShow] = createSignal(false);

  const handleExpandEnter = () => {
    setShow(true);
  };

  const handleExpandLeave = () => {
    setShow(false);
  };

  return (
    <div
      data-content
      class="w-full h-full py-3 px-1 relative overflow-hidden no-underline"
      classList={{
        "active-border": props.isPath !== undefined && show(),
        ...local?.classList?.reduce<Record<string, boolean>>((obj, cls) => {
          obj[cls] = true;
          return obj;
        }, {}),
      }}
      tabIndex={props.isPath ? "0" : "-1"}
      onMouseEnter={handleExpandEnter}
      onMouseLeave={handleExpandLeave}
    >
      <div>
        {props.pageTitle ? (
          <h1
            class={
              /* @once */ `${local.titleFont || ""} font-bold w-full mt-1 mb-3 ml-6 text-${
                local.titleSize || "2xl"
              }`
            }
          >
            <Show when={props.title}>{/* @once */ props.title}</Show>
          </h1>
        ) : (
          <h3
            class={
              /* @once */ `${local.titleFont || ""} ff-serif max-md:text-lg font-bold w-full pl-3 mb-2.5 text-balance`
            }
          >
            <Show when={props.title}>{/* @once */ props.title}</Show>
          </h3>
        )}
      </div>
      {/* card content start */}
      <div
        class={`${as === "card" ? "template-cb" : "content-wrapper"} text-neutral-50`}
      >
        {c()}
      </div>
      {/* card content end */}
      {props.isPath && (
        <ArrowIcon show={show()} rotate0={!show()} rotate90={show()} />
      )}
    </div>
  );
};

export default CardLayout;
