function ArrowIcon(props: {
  show: unknown;
  rotate0: boolean;
  rotate90: boolean;
}) {
  return (
    <div class="absolute overflow-hidden w-9 h-9 z-1 right-0 bottom-0 shadow-xl">
      {/* Doodle border start */}
      <svg
        display="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class={`pop-fill`}
        width="50"
        height="50"
      >
        <path
          fill="#1b1b1b"
          class={`fill-current rotate-90 ${props.show ? "text-primary-100" : "text-neutral-50"}`}
          classList={{ rotate0: props.rotate0, rotate90: props.rotate90 }}
          stroke-width="5"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.27 8.36a11.87834 11.87834 0 0 0-2.4892-4.36468C19.6494 2.73454 18.2614 1.73031 16.71 1.05a12.259511 12.259511 0 0 0-2.6-.819999C11.1643-.126095 8.18833.583309 5.72002 2.23c-1.96265 1.1391-3.54809 2.82885-4.56 4.86a14.370021 14.370021 0 0 0-.999999 3.68 16.000097 16.000097 0 0 0-.11 3.81c.164159 1.4252.653397 2.7937 1.429999 4 .75755 1.1732 1.69192 2.2223 2.77 3.11 1.22765 1.0228 2.69835 1.7117 4.27 2 1.52018.2799 3.07258.3405 4.60998.18 2.3042-.1894 4.5328-.9118 6.51-2.11 1.3432-.836 2.4297-2.0263 3.14-3.44 1.4437-3.1321 1.6193-6.7013.49-9.96Zm-1.43 9.43c-.8207 1.5301-2.1204 2.749-3.7 3.47-1.596.7759-3.3216 1.2505-5.09 1.4-1.4304.147-2.8744.0931-4.28998-.16a9.929548 9.929548 0 0 1-3.63424-1.602c-1.09117-.7732-2.0149-1.759-2.71576-2.898a8.409989 8.409989 0 0 1-1.33-3.5 14.270106 14.270106 0 0 1 0-3.57 13.389887 13.389887 0 0 1 .92-3.5c.89541-1.8939 2.32372-3.48555 4.11-4.58C7.86591 1.66334 9.89065.934566 12 .730001a.329928.329928 0 0 0 .14.115808c.0565.024749.1189.03313.18.024192a5.40995 5.40995 0 0 1 1.68.06A11.600378 11.600378 0 0 1 16.43 1.75a9.150037 9.150037 0 0 1 1.9 1.1c1.8879 1.47294 3.283 3.48537 4 5.77 1.0309 3.0047.8552 6.2922-.49 9.17Z"
        />

        {/* Doodle border end */}
        <svg
          display="none"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#1b1b1b"
            class={`expand-arrow-start fill-current ${props.show ? "active" : ""}`}
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.69692 11.0076C3.96044 9.37854 3.43687 7.71414 3.14296 6.06721C3.03656 5.40202 2.75441 4.59424 3.38521 4.11993C4.96494 2.96329 12.5629 5.03196 12.9707 7.21702C13.1618 8.24975 10.2094 8.25098 9.25307 8.05187C10.0416 9.06858 14.6313 14.9474 15.0729 15.8015C15.101 15.8691 15.1064 15.938 15.0882 15.9954C15.07 16.0529 15.0294 16.0952 14.9739 16.1144C14.9184 16.1338 14.8517 16.1288 14.7859 16.1004C14.7202 16.0722 14.6599 16.0223 14.6161 15.9602C14.0705 14.9779 8.68346 8.5027 8.25274 7.90834C7.82203 7.31397 8.28651 6.94219 8.97685 7.15944C10.0293 7.35348 11.0409 7.30218 11.936 7.00936C11.7427 6.81804 11.5236 6.65962 11.2922 6.54391C9.55268 5.52948 3.96709 3.94342 4.04653 5.42588C4.34317 7.59687 5.01458 9.81021 6.02894 11.9618C6.43922 10.735 6.0331 8.7863 6.35729 8.49367C6.45051 8.44397 6.56914 8.43803 6.69086 8.47697C6.81269 8.5159 6.9293 8.59704 7.01884 8.70515C7.93635 9.89083 6.14318 8.10017 14.6042 16.1295C14.6522 16.1995 14.6787 16.278 14.6793 16.3512C14.6798 16.4245 14.6544 16.4879 14.6073 16.5304C14.5603 16.5729 14.4946 16.5918 14.4218 16.5837C14.3489 16.5758 14.2736 16.5413 14.2088 16.4865C14.264 16.5476 8.52456 11.7544 7.10031 9.91617C7.10909 10.8122 7.26764 12.239 6.57816 12.909C6.26704 13.19 5.79144 13.0801 5.31229 12.2758C5.08607 11.8588 4.88051 11.4354 4.69692 11.0076Z"
          />
        </svg>

        <svg
          display="none"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 16"
          width="19"
          height="20"
        >
          <path
            fill="#1b1b1b"
            class={`expand-arrow-active ${props.show ? "active" : ""}`}
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.07198 10.0076C3.3355 8.37854 2.81193 6.71414 2.51802 5.06721C2.41162 4.40202 2.12947 3.59424 2.76027 3.11993C4.34 1.96329 11.938 4.03196 12.3457 6.21702C12.5368 7.24975 9.58448 7.25098 8.62813 7.05187C9.41661 8.06858 14.0064 13.9474 14.448 14.8015C14.476 14.8691 14.4814 14.938 14.4632 14.9954C14.445 15.0529 14.4045 15.0952 14.349 15.1144C14.2934 15.1338 14.2267 15.1288 14.161 15.1004C14.0953 15.0722 14.035 15.0223 13.9912 14.9602C13.4456 13.9779 8.05852 7.5027 7.6278 6.90834C7.19709 6.31397 7.66157 5.94219 8.35191 6.15944C9.40438 6.35348 10.416 6.30218 11.311 6.00936C11.1177 5.81804 10.8986 5.65962 10.6673 5.54391C8.92774 4.52948 3.34215 2.94342 3.42159 4.42588C3.71823 6.59687 4.38964 8.81021 5.404 10.9618C5.81428 9.73497 5.40816 7.7863 5.73235 7.49367C5.82557 7.44397 5.9442 7.43803 6.06592 7.47697C6.18775 7.5159 6.30437 7.59704 6.3939 7.70515C7.31141 8.89083 5.51824 7.10017 13.9793 15.1295C14.0272 15.1995 14.0538 15.278 14.0543 15.3512C14.0549 15.4245 14.0294 15.4879 13.9824 15.5304C13.9353 15.5729 13.8696 15.5918 13.7968 15.5837C13.724 15.5758 13.6486 15.5413 13.5839 15.4865C13.6391 15.5476 7.89962 10.7544 6.47537 8.91617C6.48415 9.81217 6.6427 11.239 5.95322 11.909C5.6421 12.19 5.1665 12.0801 4.68735 11.2758C4.46113 10.8588 4.25557 10.4354 4.07198 10.0076Z"
          />
          <path
            class={`expand-arrow-active ${props.show ? "active" : ""}`}
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.45956 2.82044C7.44449 2.88817 7.39868 2.94961 7.33027 2.9939C7.26186 3.03821 7.17524 3.0625 7.08573 3.0625C6.99623 3.0625 6.9096 3.03821 6.84119 2.9939C6.77278 2.94961 6.72699 2.88817 6.71191 2.82044C6.71191 2.72896 6.00754 0.999092 7.167 0.708C7.9905 0.483455 7.63293 2.16342 7.45956 2.82044ZM12.9825 5.37292C12.9235 5.41367 12.843 5.43691 12.7587 5.43749C12.6743 5.43809 12.5932 5.41597 12.5329 5.37605C12.4727 5.33612 12.4384 5.28163 12.4375 5.22457C12.4366 5.16751 12.4693 5.11256 12.5283 5.0718C12.6934 4.90288 12.8967 4.75258 13.1307 4.62639C13.588 4.40539 14.1272 4.27504 14.6879 4.25C14.7435 4.25 14.9381 4.41938 14.6879 4.62012C13.7332 5.39801 13.3995 5.19726 12.9825 5.37292ZM5.21667 12.5625C5.25478 12.5828 5.28459 12.618 5.30038 12.6611C5.31606 12.7042 5.31653 12.7523 5.30169 12.7957C5.22735 13.5188 4.31321 13.8628 4.18045 13.7171C4.16288 13.6986 4.14898 13.6764 4.13937 13.6518C4.12987 13.6273 4.125 13.6009 4.125 13.5743C4.125 13.5475 4.12987 13.5212 4.13937 13.4966C4.14898 13.472 4.16288 13.4498 4.18045 13.4314C4.41676 13.0117 4.7856 12.7025 5.21667 12.5625ZM0.791446 6.78842C1.20161 6.78842 2.13653 6.19955 2.84262 7.25753C2.86518 7.2785 2.88465 7.30585 2.90009 7.33801C2.91553 7.37017 2.92645 7.40653 2.93239 7.445C2.93833 7.48345 2.93904 7.52328 2.93465 7.56218C2.93025 7.60108 2.92063 7.63831 2.9065 7.67174C2.89237 7.70515 2.87397 7.73412 2.85235 7.75698C2.83062 7.77982 2.80616 7.79613 2.78027 7.80493C2.75427 7.81373 2.72743 7.81488 2.7013 7.80829C2.67506 7.80171 2.65 7.78752 2.62744 7.76655C1.97574 7.83823 1.32095 7.72648 0.697277 7.43718C0.64954 7.40529 0.610591 7.35008 0.587078 7.28077C0.563447 7.21145 0.556559 7.13223 0.567603 7.05632C0.578647 6.98041 0.606909 6.9124 0.64764 6.86364C0.688372 6.81489 0.739077 6.78833 0.791446 6.78842Z"
            fill="#1B1B1B"
          />
        </svg>
      </svg>
    </div>
  );
}

export default ArrowIcon;
